let localizations_sv = {
  content: {
    title: {
      title: 'Kandidatrespons'
    },
    kysely: {
      information: 'Om enkäten',
      about1: 'Kandidatrespons är universitetens riksomfattande undersökning, som utvärderar erfarenheter av hur studierna förlöpt och hur nöjd studerande är med sitt universitet. Enkäten grundar sig på vetenskaplig forskning om inlärning, studier och undervisning som hör till HowULearn-enkäten som utvecklas av Helsingfors universitet. Undersökningens resultat utnyttjas till att utveckla utbildningen och nationellt i styrning av universiteten. Enkäten besvaras av de universitetsstuderande som har avlagt en lägre högskoleexamen eller motsvarande.',
      about2: 'Med hjälp av studerandes HAKA-inloggning hittas personens information om studierätt från nationella VIRTA-studieregistret. Uppgifterna används som bakgrundsinformation för att skapa svarsidentifiering och gör de möjligt att ge respons i elektronisk form. Information som möjliggör personlig identifiering överförs inte vidare till själva Kandidatrespons-enkäten, utan materialet behandlas anonymt.',
      about3: 'Kandidatrespons-enkäten koordineras och utvecklas av de finländska universiteten.',
      privacy: {
        text: 'Utförligare information i <1>dataskyddsbeskrivningen</1>, <3>etiska principer</3> och <5>tillgänglighetsutlåtande</5>.',
        privacyNoticeLink: 'https://wiki.eduuni.fi/display/CscArvo/Tietosuojailmoitus+Kandipalaute',
        ethicalPrinciplesLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=115052122',
        accessibilityLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=303291918'
      }
    },
    login: {
      header: 'Studerande vid universitet',
      description: 'Svara på enkäten genom att logga in med ditt eget universitets användarnamn och lösenord:',
      alt: 'Haka-inloggning'
    },
    tulokset: {
      header: 'Undersökningsresultaten',
      results1: 'Universitetets personal kan följa med enkätens resultat i realtid via utbildningsförvaltningens tjänst ”Arvo”. Ansökan om läsrättigheter (Arvo-katselija) kan anhållas via Opintopolku (virkailijan työpöytä, omat tiedot).',
      results2: 'Forskningsmaterialets nationella andel (numeriska uppgifter) utlämnas med registeransvariges tillstånd pseudonymiserat till Samhällsvetenskapliga dataarkivet.  Uppgifterna blir anonyma i dataarkivet när datalagringstiden upphör i de personuppgiftsansvarigas egna system och materialen förstörs (mer information: <1>www.fsd.tuni.fi</1>). Statistiska rapporter av enkätens resultat finns till allmänt påseende ',
      vipunen_link: 'via utbildningsförvaltningens statistiktjänst Vipunen.',
      link1: {
        url: 'https://www.fsd.uta.fi/',
        text: 'www.fsd.uta.fi'
      }
    }
  },
  footer: {
    header: 'I samarbete med:',
    oha: {
      description: 'Specialister inom universitetens studieärenden och -administration, OHA-forum',
      logo_alt: 'OHA-forum logo'
    },
    okm: {
      description: 'Undervisnings- och kulturministeriet',
      logo_alt: 'Undervisnings- och kulturministeriet logo'
    },
    csc: {
      description: 'Arvo - informationstjänst för effektivitetshantering i utbildningsförvaltning',
      logo_alt: 'CSC logo'
    }
  },
  profiledata: {
    header: 'Bakgrundsinformation till enkäten',
    about: 'Som bakgrundsinformation för enkäten används följande uppgifter: utbildning, yrkeshögskola, yrkeshögskolans kommun, språket för examen och inledningsdatum för studierna. Personuppgifter förmedlas inte vidare till enkäten och alla svar behandlas anonymt.',
    privacy_notice: {
      text: 'Dataskyddsbeskrivningen finns här <1>https://wiki.eduuni.fi/x/lZGsBQ</1>',
      link: 'https://wiki.eduuni.fi/x/lZGsBQ'
    },
    education: 'Utbildning',
    school: 'Universitet',
    municipality: 'Kommun',
    startYear: 'Inledningsdatum för studierna',
    language: 'Språket för examen',
    funding: 'Finansieringskälla',
    form_of_education: 'Undervisningsform',
    reminder: 'Var god och kontrollera att uppgifterna om din utbildning är korrekta. Om det finns brister eller fel i dina uppgifter, kontakta din studiebyrå. Genom att gå vidare till enkäten godkänner du att uppgifterna används som bakgrundsinformation för enkäten.',
    survey: 'Gå till enkäten',
    type: {
      day: 'Dagundervisning',
      multi: 'Flerformsundervisning'
    },
    submit: 'Gå till enkäten'
  },
  errors: {
    title: 'Felsituation',
    general_error: 'Tyvärr uppstod ett ospecificerat fel. Vänligen försök igen senare och om felet återkommer, kontakta arvo@csc.fi',
    haka_error: 'Tyvärr lämnade Haka inte den uppgifter som krävs när du loggade in. Kontakta din egen läroanstalt.',
    virta_error: 'Där var ett fel när din  information hämtades från VIRTA högskoleregister. Om situationen upprepar sig, kontakta din egen läroanstalt.',
    no_data_in_virta: 'Tyvärr stämmer dina inloggningsuppgifter inte med informationen som finns i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    arvo_error: 'Tyvärr enkäten kunde inte öppnas. Vänligen försök igen senare och om felet återkommer, kontakta arvo@csc.fi',
    attach_code: 'Bifoga denna felkod i meddelandet'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Din studierätt tillhör inte den högskola som din HAKA-inloggning ger till känna.',
    invalid_type: 'Studierättens typ är inkorrekt i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    invalid_date: 'Enligt den information som hämtas från studieinformationstjänsten VIRTA är din studierätt inte giltig. Kontakta din egen läroanstalt.',
    invalid_laajuus: 'Ofullständig information i studieinformationstjänsten VIRTA. Omfattningen av studierätten måste vara mer än 0 sp. Kontakta din egen läroanstalt.',
    not_active: 'Din studierätt är inte aktiv. Kontakta din egen läroanstalt.',
    not_enough_opintosuoritus: 'Du har inte tillräckligt med studiepoäng i studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    no_kandi: 'Du har ingen avlagd kandidatexamen i studieregistret. Kontakta din egen läroanstalt.',
    no_patevyys: 'Du har inte den nödlig Valvira yrkeskvalifikationen i studieregistret. Kontakta din egen läroanstalt.',
    header: 'Inga studierätter hittades.',
    no_rights_contact_study_office: 'Inga studierätter hittades med dina uppgifter från studieinformationstjänsten VIRTA. Kontakta din egen läroanstalt.',
    some_rights_contact_study_office: 'Med dina uppgifter hittades {{rights_count}} studierätter i studieinformationstjänsten VIRTA, men informationen räcker inte för att ge respons. Kontakta din egen läroanstalt.',
    additional_invalid_rights: 'Dessutom hittades {{rights_count}} studierätter, men informationen räcker inte för att ge respons. Kontakta din egen läroanstalt.'
  }
};

export default localizations_sv;
