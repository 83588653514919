import {useCallback, useEffect, useState} from 'react';
import LocalizedThemeImage from '../common/localizedimage/localizedthemeimage';
import request from '../../util/request'
import {handleError} from '../../util/error.js'
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import './userprofile.scss'

function selectStudyRight(setState, state, event) {
  setState(state => ({
    ...state,
    selectedStudyRight: state.valid_rights
      .find(opiskeluoikeus => opiskeluoikeus.id === event.target.value)
  }));
}

function onError(navigate, lang, state, e) {
  if (e.json) {
    handleError(lang, e.json, navigate);
  } else {
    handleError(lang, {
      sessionid: state.sessionid,
      exception: e}, navigate);
  }
}

function onSubmit(event, navigate, state, lang) {
  event.preventDefault();
  let data = {
    opiskeluoikeus_id: state.selectedStudyRight.id,
    oppilaitos_id: state.oppilaitos,
    kieli: lang && lang.substring(0, 2),
    tyyppi: 'kandipalaute',
  };
  request('/api/rekisteroidy', {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(registration => {
      if (registration['kysely_url']) {
        window.location = registration['kysely_url']
      } else {
        handleError(lang, registration, navigate)
      }
    })
    .catch(e => onError(navigate, lang, state, e));
}

export default function Userprofile() {
  const [state, setState] = useState({
    valid_rights: [],
    invalid_rights: [],
    oppilaitos: null,
    sessionid: null,
    selectedStudyRight: {},
    isInitialised: false,
  });
  const {t, i18n} = useTranslation();
  const lang = i18n.language;
  const navigate = useNavigate();
  const onSubmitCallback = useCallback((e) => {
    onSubmit(e, navigate, state, lang);
  }, [lang, navigate, state]);

  useEffect(() => {
    let hasStorage = 'sessionStorage' in window && window.sessionStorage;
    let key = 'opiskeluoikeudet';
    let data = hasStorage ?
      sessionStorage.getItem(key) : null;

    if (hasStorage && data) {
      let now = new Date();
      let expiration = new Date(data.timestamp);
      expiration.setMinutes(expiration.getMinutes() + 15);
      if (now.getTime() > expiration.getTime()) {
        data = null;
        sessionStorage.removeItem(key);
      }
    }

    async function fetchOpiskeluoikeudet() {
      try {
        const study_rights = await request('/api/opiskeluoikeudet/kandi', {credentials: 'same-origin'});
        setState(state => ({
          ...state,
          valid_rights: study_rights['valid'],
          invalid_rights: study_rights['invalid'],
          oppilaitos: study_rights['oppilaitos_id'],
          sessionid: study_rights['sessionid'],
          selectedStudyRight: study_rights['valid'][0],
        }));
      } catch(e) {
        handleError(lang, e.json, navigate);
      } finally {
        setState(state => ({...state, isInitialised: true }));
      }
    }
    if (!state.isInitialised) {
      fetchOpiskeluoikeudet();
    }
  }, [lang, navigate, state.isInitialised]);

  if (!state.isInitialised) {
    return null;
  }
  if (state.valid_rights.length === 0) {
    return(
      <div>
        <LocalizedThemeImage />
        <section id="no-rights">
          <div className="container">
            <div className="row">
              <div className="u-full-width">
                <h1>{t('opiskeluoikeus_errors.header')}</h1>
              </div>

              {((state.invalid_rights.length > 0) ?
                  <div>
                    <p>{t('opiskeluoikeus_errors.some_rights_contact_study_office', {rights_count: state.invalid_rights.length})}</p>

                    {state.invalid_rights.map(r =>
                      <div> {r.koulutus.nimi[lang]} - {t('opiskeluoikeus_errors.'+r.virheet[0])}</div>
                    )}
                  </div> :
                  <p>{t('opiskeluoikeus_errors.no_rights_contact_study_office')}</p>
              )}
            </div>
          </div>
        </section>
      </div>);
  }
  return (
    <div>
      <LocalizedThemeImage />
      <section id="userprofile">
        <div className="container">
          <div className="row">
            <div className="u-full-width">
              <h1>{t('profiledata.header')}</h1>
            </div>
            <div className="u-full-width">
              <p>{t('profiledata.about')}</p>
            </div>
            <div className="u-full-width">
              <Trans i18nKey="profiledata.privacy_notice.text">
                Kyselyn tietosuojaseloste löytyy täältä
                <a href={t('profiledata.privacy_notice.link')} target="_blank" rel="noreferrer">tietosuojaseloste</a>
              </Trans>
            </div>

            <form onSubmit={onSubmitCallback}>
              {(state.valid_rights.length > 1) ?
                <select onChange={(e) => selectStudyRight(setState, state, e)}
                        value={state.selectedStudyRight.id}>
                  {
                    state.valid_rights.map(sr =>
                      <option value={sr.id}>{sr.koulutus.nimi[lang]}</option>
                    )
                  }
                </select>
                : ''
              }
              <div className="u-full-width">
                <dl>
                    <div className="dl-row row">
                      <dt>{t('profiledata.education')}</dt>
                      <dd>{state.selectedStudyRight.koulutus.nimi[lang]}</dd>
                    </div>

                    <div className="dl-row row">
                      <dt>{t('profiledata.school')}</dt>
                      <dd>{state.selectedStudyRight.oppilaitos.nimi[lang]}</dd>
                    </div>

                    <div className="dl-row row">
                      <dt>{t('profiledata.municipality')}</dt>
                      <dd>{state.selectedStudyRight.koulutuskunta.nimi[lang]}</dd>
                    </div>

                    <div className="dl-row row">
                      <dt>{t('profiledata.startYear')}</dt>
                      <dd>{state.selectedStudyRight.aloituspvm.year}</dd>
                    </div>

                    <div className="dl-row row">
                      <dt>{t('profiledata.language')}</dt>
                      <dd>{state.selectedStudyRight.suorituskieli}</dd>
                    </div>
                </dl>
              </div>
              <div className="u-full-width">
                <p>{t('profiledata.reminder')}</p>
              </div>
              <div className="u-full-width">
                <button type="submit">
                  <span>{t('profiledata.submit')}</span>
                </button>
              </div>
              <div>
                {(state.invalid_rights.length > 0 && state.valid_rights === 0) ?
                  <p>{t('opiskeluoikeus_errors.some_rights_contact_study_office', {rights_count: state.invalid_rights.length})}</p>
                  : ''
                }
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )

}
