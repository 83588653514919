let localizations_fi = {
  content: {
    title: {
      title: 'Yliopistojen valtakunnallinen opiskelijapalautekysely (Kandipalaute)'
    },
    kysely: {
      information: 'Tietoa kyselystä',
      about1: 'Kandipalaute on palautekysely, jossa selvitetään kandidaatin tutkinnon suorittaneiden opiskelijoiden tyytyväisyyttä yliopistoonsa ja kokemuksia opintojen sujumisesta. Kysely perustuu oppimista, opiskelua ja opetusta koskevaan tieteelliseen tutkimukseen, ja sen pohjalta kehitettyyn Helsingin yliopiston HowULearn-kyselyyn. Kyselyn tuloksia hyödynnetään yliopistojen koulutuksen kehittämisessä sekä valtakunnallisesti yliopistojen ohjauksessa. Kyselyn täyttävät kaikki alempaan korkeakoulututkintoon kuuluvat tai vastaavat opinnot suorittaneet yliopisto-opiskelijat.',
      about2: 'Opiskelijan HAKA-kirjautumisen avulla haetaan henkilön opiskeluoikeuteen liittyviä tietoja VIRTA-opintotietopalvelusta taustatiedoksi Kandipalaute-kyselyn vastaajatunnuksen luomiseksi ja sähköisen palautteen antamisen mahdollistamiseksi. Henkilön yksilöiviä tunnisteita ei välitetä eteenpäin itse Kandipalaute-kyselyyn ja annettua palautetta käsitellään anonyymisti.',
      about3: 'Kandipalaute-kyselystä ja sen kehittämisestä vastaavat Suomen yliopistot.',
      privacy: {
        text: 'Lisätietoa: <1>tietosuojailmoitus</1>, <3>kyselyn eettiset periaatteet</3> ja <5>saavutettavuusseloste</5>.',
        privacyNoticeLink: 'https://wiki.eduuni.fi/display/CscArvo/Tietosuojailmoitus+Kandipalaute',
        ethicalPrinciplesLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=115052122',
        accessibilityLink: 'https://wiki.eduuni.fi/display/CscArvo/Arvo+saavutettavuusseloste'
      }
    },
    login: {
      header: 'Yliopisto-opiskelija',
      description: 'Vastaa kyselyyn kirjautumalla oman korkeakoulusi tunnuksilla:',
      alt: 'Haka-kirjautuminen'
    },
    tulokset: {
      header: 'Kyselyn tulokset',
      results1: 'Yliopiston henkilökunta voi tarkastella kyselyn tuloksia reaaliaikaisesti Arvo - opetushallinnon vaikuttavuustietopalvelun avulla. Katseluoikeudet (Arvo-katselija) voi anoa Opintopolun virkailijantyöpöydän kautta (Omat tiedot).',
      results2: 'Valtakunnallinen osuus tutkimusaineistosta luovutetaan pseudonymisoituna Yhteiskuntatieteelliseen tietoarkistoon pysyvää arkistointia varten ja  luovutettavaksi tutkimukseen, opetukseen ja opiskeluun rekisterinpitäjien luvalla. Aineisto muuttuu tietoarkistossa anonyymiksi, kun aineistojen säilytysaika päättyy rekisterinpitäjien omissa järjestelmissä ja aineistot hävitetään (Lisätietoja: <1>www.fsd.tuni.fi</1>). Kyselyn tuloksista johdettuja tilastoraportteja on avoimesti saatavilla ',
      vipunen_link: 'opetushallinnon tilastopalvelu Vipusessa.',
      link1: {
        url: 'https://www.fsd.uta.fi/',
        text: 'www.fsd.uta.fi'
      }
    }
  },
  footer: {
    header: 'Yhteistyössä:',
    oha: {
      description: 'Suomen yliopistokoulutuksen asiantuntijaverkosto – OHAforum',
      logo_alt: 'OHA-forumin logo'
    },
    okm: {
      description: 'Opetus- ja kulttuuriministeriö',
      logo_alt: 'Opetus- ja kulttuuriministeriön logo'
    },
    csc: {
      description: 'Arvo - opetushallinnon vaikuttavuustietopalvelu',
      logo_alt: 'CSC - Tieteen tietotekniikan keskuksen logo'
    }
  },
  profiledata: {
    header: 'Palautekyselyn taustatiedot',
    about: 'Palautekyselyyn välitetään taustatiedoiksi koulutus, yliopisto, koulutuksen kunta, tutkinnon suorituskieli ja opiskeluoikeuden alkamisvuosi. Henkilöön liittyviä tietoja ei välitetä palautekyselylle.',
    privacy_notice: {
      text: 'Kyselyn tietosuojaseloste löytyy täältä <1>https://wiki.eduuni.fi/x/lZGsBQ</1>',
      link: 'https://wiki.eduuni.fi/x/lZGsBQ'
    },
    education: 'Koulutus',
    school: 'Yliopisto',
    municipality: 'Kunta',
    language: 'Tutkinnon suorituskieli',
    funding: 'Rahoituslähde',
    startYear: 'Tutkinnon aloitusvuosi',
    form_of_education: 'Koulutusmuoto',
    reminder: 'Tarkistathan, että koulutuksesi tiedot ovat oikein. Jos tiedoissasi on korjattavaa, ota yhteyttä opintotoimistoosi. Siirtymällä palautekyselyyn hyväksyt tietojen käyttämisen palautekyselyn taustatietona.',
    survey: 'Siirry palautekyselyyn',
    type: {
      day: 'Päiväopiskelu',
      multi: 'Monimuoto-opiskelu'
    },
    submit: 'Siirry palautekyselyyn'
  },
  errors: {
    title: 'Virhetilanne',
    general_error: 'Valitettavasti järjestelmän toiminnassa tapahtui virhe. Jos ongelma toistuu, ole hyvä ja ota yhteyttä ylläpitoon arvo@csc.fi',
    haka_error: 'Valitettavasti Haka ei luovuttanut vaadittuja tietoja kirjautumisesi yhteydessä. Ota yhteyttä omaan oppilaitokseesi.',
    virta_error: 'Virhe haettaessa tietojasi VIRTA-opintotietopalvelusta. Jos tilanne toistuu, ota yhteyttä omaan oppilaitokseesi.',
    no_data_in_virta: 'Valitettavasti kirjautumistietosi eivät vastaa VIRTA-opintotietopalvelusta löydettäviä tietoja. Ota yhteyttä omaan oppilaitokseesi.',
    arvo_error: 'Valitettavasti kyselyyn siirtymisessä tapahtui virhe. Jos ongelma toistuu, ole hyvä ja ota yhteyttä ylläpitoon arvo@csc.fi',
    attach_code: 'Liitä viestiin tämä virhekoodi'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Opiskeluoikeutesi ei kuulu HAKA-kirjaumista vastaavaan korkeakouluun.',
    invalid_type: 'Väärä opiskeluoikeuden tyyppi VIRTA-opintotietopalvelussa. Ota yhteyttä omaan oppilaitokseesi.',
    invalid_date: 'VIRTA-opintotietopalvelusta haettujen tietojen mukaan opiskeluoikeutesi ei ole voimassa. Ota yhteyttä omaan oppilaitokseesi.',
    invalid_laajuus: 'Puutteellinen tieto VIRTA-opintotietopalvelussa. Opiskeluoikeuden laajuuden pitää olla yli 0 op. Ota yhteyttä omaan oppilaitokseesi.',
    not_active: 'Opiskeluoikeutesi ei ole aktiivinen. Ota yhteyttä omaan oppilaitokseesi.',
    not_enough_opintosuoritus: 'Sinulla ei ole riittävästi opintopisteitä VIRTA-opintotietopalvelussa. Ota yhteyttä omaan oppilaitokseesi.',
    no_kandi: 'Sinulla ei ole kandidaatin tutkinnon suoritusmerkintää opintorekisterissä. Ota yhteyttä omaan oppilaitokseesi.',
    no_patevyys: 'Sinulla ei ole vaadittua Valvira-pätevyysmerkintää opintorekisterissä. Ota yhteyttä omaan oppilaitokseesi.',
    header: 'Opiskeluoikeuksia ei löydy',
    no_rights_contact_study_office: 'Tiedoillasi ei löytynyt yhtään opiskeluoikeutta VIRTA-opintotietopalvelusta. Ota yhteyttä omaan oppilaitokseesi.',
    some_rights_contact_study_office: 'Tiedoillasi löytyi {{rights_count}} opiskeluoikeutta VIRTA-opintotietopalvelusta, mutta tiedot eivät riitä palautteen antamiseen. Ota yhteyttä omaan oppilaitokseesi.',
    additional_invalid_rights: 'Lisäksi löytyi {{rights_count}} oikeutta, mutta tiedot eivät riitä palautteen antamiseen. Ota yhteyttä omaan oppilaitokseesi.'
  }
};

export default localizations_fi;
