import imageUrls from './localizedimageurls';
import {useTranslation} from 'react-i18next';

function Localizedimage(props) {

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  let img = imageUrls[lang][props.image];
  let alt = (props.alt !== undefined) ? t(props.alt) : null;

  return <img src={img} alt={alt}/>
}

export default Localizedimage;
