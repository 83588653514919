let localizations_en = {
  content: {
    title: {
      title: 'The Finnish Bachelor’s Graduate Survey (Kandipalaute)'
    },
    kysely: {
      information: 'About the questionnaire',
      about1: 'The Finnish Bachelor’s Graduate Survey is a national student feedback survey that examines students’ satisfaction with their university and studying experience. The survey is founded on HowULearn-questionnaire that was developed by the University of Helsinki based on prior research on academic teaching and learning. The findings are used by institutions to improve education and learning and nationally to inform performance-based monitoring and guidance. All graduating Bachelor degree students or students with corresponding studies from universities are requested to fill out the questionnaire.',
      about2: 'Collected personal data is used to fetch information related to person’s study right from VIRTA - higher education achievement register as background information to create a respondent ID and enable the use of electronic graduate feedback questionnaire. The collected personal data is not transferred to the questionnaire and all answers are handled confidentially and anonymized.',
      about3: 'The Finnish universities are responsible for the graduate feedback survey and its development.',
      privacy: {
        text: 'More information in <1>Privacy notice</1>, <3>Ethical principles</3> and <5>Accessibility statement</5>.',
        privacyNoticeLink: 'https://wiki.eduuni.fi/display/CscArvo/Tietosuojailmoitus+Kandipalaute',
        ethicalPrinciplesLink: 'https://wiki.eduuni.fi/pages/viewpage.action?pageId=115052122',
        accessibilityLink: 'https://wiki.eduuni.fi/display/CscArvo/Arvo+Accessibility+Statement',
      }
    },
    login: {
      header: 'University student',
      description: 'Please sign in to the survey by using your university credentials:',
      alt: 'Haka login'
    },
    tulokset: {
      header: 'The results',
      results1: 'The staff of the universities can review the real time survey results by using the Arvo - education management information service with appropriate user rights.',
      results2: 'The national part (numeric data) of the research data is disclosed in pseudonymised form to the Finnish Social Science Data Archive for permanent archival and research purposes with the permission of the controllers. The data becomes anonymous in the data archive when the retention period of the data ends in the controllers’ own systems and data is disposed. (More information: <1>www.fsd.tuni.fi</1>). The statistics are freely available at the ',
      vipunen_link: 'Vipunen statistics services of the educational administration.',
      link1: {
        url: 'https://www.fsd.uta.fi/',
        text: 'www.fsd.uta.fi'
      }
    }
  },
  footer: {
    header: 'In cooperation:',
    oha: {
      description: 'Finnish Academic Affairs Specialists, OHA-forum',
      logo_alt: 'OHA-forum logo'
    },
    okm: {
      description: 'Ministry of Education and Culture',
      logo_alt: 'Ministry of education and culture logo'
    },
    csc: {
      description: 'Arvo - education management information service',
      logo_alt: 'CSC logo'
    }
  },
  profiledata: {
    header: 'Background information',
    about: 'Information on education, university, municipality, language of learning and start date of the right to study is used as survey background information. The collected personal information is not transferred to the feedback system.',
    privacy_notice: {
      text: 'The privacy notice of the survey can be found here <1>https://wiki.eduuni.fi/x/lZGsBQ</1>',
      link: 'https://wiki.eduuni.fi/x/lZGsBQ'
    },
    education: 'Education',
    school: 'University',
    municipality: 'Municipality',
    language: 'Language of learning',
    funding: 'Funding source',
    startYear: 'Start date of the right to study',
    form_of_education: 'Mode of education',
    reminder: 'Please confirm the education information is correct. In case of errors, please contact your study office. Once you move to the feedback questionnaire, you accept that the information is used as background information.',
    survey: 'Go to the survey',
    type: {
      day: 'Daytime studies',
      multi: 'Multiform studies'
    },
    submit: 'Go to the survey'
  },
  errors: {
    title: 'Error situation',
    general_error: 'Unfortunately an unspecified error occurred. Please try again later and if the error reoccurs, contact arvo@csc.fi',
    haka_error: 'Unfortunately Haka did not provide the required information when you logged in. Contact your own educational institution.',
    virta_error: 'There was an error when retrieving your information from the VIRTA higher education achievement register. If this  situation happens again, contact your own educational institution.',
    no_data_in_virta: 'Unfortunately your login information does not match the information found in the VIRTA higher education achievement register. Contact your own educational institution.',
    arvo_error: 'Unfortunately an error occurred when trying to access the questionnaire. Please try again later and if the error reoccurs, contact arvo@csc.fi',
    attach_code: 'Attach this error code to the message'
  },
  opiskeluoikeus_errors: {
    invalid_organization: 'Your right to study does not correspond to the HAKA home organisation.',
    invalid_type: 'The type of the right to study is incorrect in the VIRTA higher education achievement register. Contact your own educational institution.',
    invalid_date: 'According to the information retrieved from the VIRTA higher education achievement register, your right to study is not valid. Contact your own educational institution.',
    invalid_laajuus: 'Incomplete information in the VIRTA higher education achievement register. The scope of the right to study must be more than 0 credits. Contact your own educational institution.',
    not_active: 'Your right to study is not active. Contact your own educational institution.',
    not_enough_opintosuoritus: 'You don\'t have enough credits in the VIRTA higher education achievement register. Contact your own educational institution.',
    no_kandi: 'You don\'t have a finished bachelor\'s degree registered in the student information system. Contact your own educational institution.',
    no_patevyys: 'You don\'t have the required Valvira qualification in the study information system. Contact your own educational institution.',
    header: 'Rights to study weren\'t found.',
    no_rights_contact_study_office: 'No rights to study were found with your information from the VIRTA higher education achievement register. Contact your own educational institution.',
    some_rights_contact_study_office: 'With your information, {{rights_count}} study rights were found in the VIRTA higher education achievent register, but the information is not sufficient for giving feedback. Contact your own educational institution.',
    additional_invalid_rights: 'In addition, {{rights_count}} rights to study were found without the required information to grant access to the graduate survey. Contact your own educational institution.'
  }
};

export default localizations_en;
