import {Link, useLocation} from 'react-router-dom';
import './localeswitcher.scss';

function extractPathElement(location) {
  let matches = location.pathname
    .match(/^\/([a-z]+)\/(.*)$/);
  return matches ? '/' + matches[2] : '';
}

export default function Localeswitcher() {
  const location = useLocation();
  let path = extractPathElement(location);
  let wp = lang => '/' + lang + path;
  return (
    <nav id="languages">
      <Link
        className="language-selection"
        to={wp('fi')}
        reloadDocument
        aria-current={location.pathname.startsWith("/fi") ? "page" : "false"}
        lang="fi"
      >
        Suomeksi
      </Link>
      <Link
        className="language-selection"
        to={wp('sv')}
        reloadDocument
        aria-current={location.pathname.startsWith("/sv") ? "page" : "false"}
        lang="sv"
      >
        På Svenska
      </Link>
      <Link
        className="language-selection"
        to={wp('en')}
        reloadDocument
        aria-current={location.pathname.startsWith("/en") ? "page" : "false"}
        lang="en"
      >
        In English
      </Link>
    </nav>
  );
}

