import {useTranslation} from 'react-i18next';
import './localizedthemeimage.scss'

export default function LocalizedBackgroundImage(props) {
  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  let alt = (props.alt !== undefined) ? t(props.alt) : null;
  return <div id="theme" role="img" aria-label={alt} className={lang}></div>
}

