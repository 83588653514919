import {StrictMode} from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './index.css';
import localizations_fi from './localizations/localizations_fi';
import localizations_en from './localizations/localizations_en';
import localizations_sv from './localizations/localizations_sv';
import Main from './components/Main';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import reportWebVitals from './reportWebVitals';

let path = window.location.pathname;
let language;
if (path.indexOf('/en') === 0) {
  language = 'en';
} else if (path.indexOf('/sv') === 0) {
  language = 'sv';
} else {
  language = 'fi';
}

i18next
  .use(initReactI18next)
  .init({
    lng: language,
    resources: {fi: {translations: localizations_fi}, sv: {translations: localizations_sv}, en: {translations: localizations_en}},
    interpolation: { escapeValue: false },  // React already does escaping
    // keySeparator: '.',
    ns: 'translations',
  });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={'/' + language} replace />} />
        <Route path="/:lang/*" element={<Main />}/>
      </Routes>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
