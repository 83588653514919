import config from '../../config/base';
import LocalizedThemeImage from '../common/localizedimage/localizedthemeimage';

import './content.scss';

import hakaLoginImage from '../../images/Haka_login_vaaka_lg.jpg';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

function extractCurrentDomain() {
  return window.location.protocol + '//' +
    window.location.hostname +
    ((window.location.port === '') ? '' : ':' + window.location.port);
}

function Content() {
  const {t} = useTranslation();
  const {lang} = useParams();

  return (
    <div>
      <LocalizedThemeImage alt=""/>

      <section id="heading">
        <div className="container">
          <div className="row">
            <div className="u-full-width">
              <h1>{t('content.title.title')}</h1>
            </div>
          </div>
        </div>
      </section>

      <section id="login" aria-label={t('content.login.alt')}>
        <div className="container">
          <div className="row">

            <div id="haka-login">
              <h2>{t('content.login.header')}</h2>
              <p id="login-description">{t('content.login.description')}</p>
            </div>

            <div id="haka" className="centered">
              <a aria-describedby="login-description" href={config.hakaLoginUrl(extractCurrentDomain(), lang)}>
                <img src={hakaLoginImage} alt={t('content.login.alt')} tabIndex="0"/>
              </a>
            </div>

          </div>
        </div>
      </section>

      <section id="main_content">
        <div className="container">
          <div className="row">
            <div className="u-full-width">
              <h2>{t('content.kysely.information')}</h2>
            </div>
            <div className="u-full-width">
              <p>{t('content.kysely.about1')}</p>
            </div>
            <div className="u-full-width">
              <p>{t('content.kysely.about2')}</p>
            </div>
            <div className="u-full-width">
              <p>{t('content.kysely.about3')}</p>
              <p>
                <Trans i18nKey="content.kysely.privacy.text">
                  Lisätietoa:
                  <a href={t('content.kysely.privacy.privacyNoticeLink')} target="_blank" rel="noreferrer">tietosuojailmoitus</a>,
                  <a href={t('content.kysely.privacy.ethicalPrinciplesLink')} target="_blank" rel="noreferrer">kyselyn eettiset periaatteet</a>
                  ja
                  <a href={t('content.kysely.privacy.accessibilityLink')} target="_blank" rel="noreferrer">saavutettavuusseloste</a>.
                </Trans>
              </p>
            </div>
            <div className="u-full-width">
              <h2>{t('content.tulokset.header')}</h2>
            </div>
            <div className="u-full-width">
              <p>{t('content.tulokset.results1')}</p>
            </div>
            <p>
              <Trans i18nKey="content.tulokset.results2">
                text1
                <a href={t('content.tulokset.link1.url')} target="_blank" rel="noreferrer">{t('content.tulokset.link1.text')}</a>
                text2
              </Trans>
              <a className="external" href="https://vipunen.fi/">
                <span>{t('content.tulokset.vipunen_link')}</span>
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content;
